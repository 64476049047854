.kooriFlag{
  position: absolute;
  right: 108px;
  top: 27px;
  width: 108px;
  cursor: pointer;

  @media only screen and  (max-Width: 812px)  {
    width: 85px;
    top: 20px;
  }
  @media only screen and  (max-Width: 600px)  {
    width: 80px;
    top: 25px;
  }
  @media only screen and  (max-Width: 500px)  {
    width: 100px;
    top: 30px;
  }
}